var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Internos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Operaciones")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Turnos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Formulario")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2 border-0"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Turnos")])])]),_c('div',{staticClass:"card-body p-0"},[_vm._m(1),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"odometro"}},[_vm._v("Odometro")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.odometro_ini),expression:"form.odometro_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.odometro_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number","id":"odemetro"},domProps:{"value":(_vm.form.odometro_ini)},on:{"blur":function($event){return _vm.validarOdometro()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "odometro_ini", $event.target.value)}}})]),(
                          _vm.form.link_odometro_ini == null ||
                            _vm.form.link_odometro_ini == ''
                        )?_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"imagen"}},[_vm._v("Foto Odometro Inicial")]),_c('input',{staticClass:"form-control-file",attrs:{"type":"file","accept":"image/x-png, image/gif, image/jpeg"},on:{"change":_vm.obtenerImagen}})]):_vm._e(),_c('div',{staticClass:"form-group col-md-4"},[(_vm.miniLogo)?_c('figure',[_c('div',{staticClass:"button-container"},[_c('img',{staticClass:"rounded mx-auto d-block",attrs:{"src":_vm.miniLogo,"alt":"Logo","width":"236","height":"125"}}),(
                                _vm.form.link_odometro_ini &&
                                  _vm.$store.getters.can(
                                    'tep.turnos.deleteOdometroInicial'
                                  )
                              )?_c('a',{attrs:{"id":"BotonEliminar","href":"#"},on:{"click":function($event){return _vm.destroyImage()}}},[_vm._v(" Eliminar "),_c('i',{staticClass:"fas fa-window-close"})]):_vm._e()])]):_vm._e()])])])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[(
                    _vm.archivoValido &&
                      _vm.form.odometro_ini &&
                      _vm.$store.getters.can('tep.turnos.odometroInicial')
                  )?_c('button',{staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.saveImage()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e(),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Turno")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])])
}]

export { render, staticRenderFns }